import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import { InlineWidget } from "react-calendly";
import "../styles/contact.scss";
import MainNavBar from "../components/MainNavBar/MainNavBar";
import Footer from "../components/Footer";
import MainLayout from "../components/MainLayout";

const Contact = () => {
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    try {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);
      let resp = await fetch("/", {
        method: "post",
        body: formData,
      });
      console.log("Response received:", resp);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainLayout>
      <div className="bg-white pointer-events-all">
        <Helmet>
          <title>HelloAR: Ar and VR Company</title>
          <meta
            name="description"
            content="If you're looking for a Ar and VR Company, please reach out to us. We've worked with brands across domains and geographies."
          />
          <meta name="keywords" content="Ar and VR Company" />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
          <meta
            property="og:image"
            content="../../videos/thumbnail/3d_ar.jpg"
          />
        </Helmet>

        <div className="container-fluid contact">
          <div className="container">
            <div className="main row">
              <div className="form col-md-12 col-lg-12 col-xl-12 contact-iframe mb-5 pb-5">
                {/* <h1 className="h1 text-light mb-5">Contact Us</h1> */}
                {/* <iframe
                className=""
                src="https://forms.zohopublic.in/helloar/form/Contactform/formperma/m17Ww9yvv8Pp43eP_BhxxLDaVr_riO8itvsSjYMJ6f4"
              ></iframe> */}
                {/* <div
                className="calendly-inline-widget"
                // style={{ minWidth: "320px", minHeight: "630px" }}
                data-url="https://calendly.com/helloar/30min?primary_color=57ca85"
              ></div> */}
                <InlineWidget url="https://calendly.com/helloar/30min?primary_color=57ca85" />
              </div>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Contact;
